@charset "utf-8";
@use "sass:color";

// Define defaults for each variable.

$base-font-family: -apple-system, 'Source Sans Pro', Tahoma, Arial, sans-serif !default;
$base-font-size: 17px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;
$brand-color: #283A90 !default;

$grey-color: #696969 !default;
$grey-color-light: color.scale($grey-color, $lightness: 40%) !default;
$grey-color-dark: color.scale($grey-color, $lightness: -25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

@mixin media-query($device) {
	@media screen and (max-width: $device) {
		@content;
	}
}

@mixin relative-font-size($ratio) {
	font-size: $base-font-size * $ratio;
}

// ======== BASE
/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
	margin: 0;
	padding: 0;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
	margin-bottom: calc($spacing-unit / 2);
}

/**
* Headings
*/
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $base-font-weight;
}

/**
 * Wrapper
 */
.wrapper {
	max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
	max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
	margin-right: auto;
	margin-left: auto;
	padding-right: $spacing-unit;
	padding-left: $spacing-unit;
	@extend %clearfix;

	@include media-query($on-laptop) {
		max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
		max-width: calc(#{$content-width} - (#{$spacing-unit}));
		padding-right: calc($spacing-unit / 2);
		padding-left: calc($spacing-unit / 2);
	}
}

/**
 * Clearfix
 */
%clearfix:after {
	content: "";
	display: table;
	clear: both;
}



/**
 * Icons
 */

.svg-icon {
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 5px;
	vertical-align: text-top;
}

.social-media-list {
	li+li {
		padding-top: 5px;
	}
}


// ======== LAYOUT

/**
* Coto Styles 
*/

table.mbtablestyle {
	border-collapse: collapse;

	>table,
	td,
	th {
		border: 0px solid black;
	}
}

div.page-content {
	padding: 9px 0;

	h3 {
		font-weight: 600;
	}

	h4 {
		text-decoration: underline;
	}
}

header.site-header {
	border-top-color: #283A90;
}

.contact-form input,
.contact-form textarea {
	font-size: 15px;
	min-height: 40px;
	padding: 5px 14px;
	width: 100%;
	border: 1px solid #818a91;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	outline: 0 none;
}

.contact-thanks {
	border: 1px solid #eeeeee;
	text-align: center;
	padding: 50px 50px;
}

.warn {
	color: gray;
	text-transform: italic;
}

.footnotes {
	margin-top: 60px;
	color: #999999;

}

* a {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&:active {
		text-decoration: underline;
		color: #283A90;
	}
}

* b {
	color: blue;
}

a.post-link {
	display: inline;
}

em.esp {
	padding-right: 20px;
	margin-right: 10px;
	// background: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/cl.svg") center right;
	background: url("/assets/icons/flag-icon-css/3.1.0/flags/4x3/cl.svg") center right;
	background-repeat: no-repeat;
	background-size: 16px 12px;
}

em.eng {
	padding-right: 20px;
	margin-right: 10px;
	// background: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/gb.svg") center right;
	background: url("/assets/icons/flag-icon-css/3.1.0/flags/4x3/gb.svg") center right;
	background-repeat: no-repeat;
	background-size: 16px 12px;
}

em.fra {
	padding-right: 20px;
	margin-right: 10px;
	// background: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/fr.svg") center right;
	background: url("/assets/icons/flag-icon-css/3.1.0/flags/4x3/fr.svg") center right;
	background-repeat: no-repeat;
	background-size: 16px 12px;
}

em.por {
	padding-right: 20px;
	margin-right: 10px;
	// background: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/br.svg") center right;
	background: url("/assets/icons/flag-icon-css/3.1.0/flags/4x3/br.svg") center right;
	background-repeat: no-repeat;
	background-size: 16px 12px;
}


/**
   * Site header
   */
.site-header {
	border-top: 5px solid $grey-color-dark;
	border-bottom: 1px solid $grey-color-light;
	min-height: $spacing-unit * 1.865;

	// Positioning context for the mobile navigation icon
	position: relative;
}

.site-title {
	@include relative-font-size(1.625);
	font-weight: 300;
	line-height: $base-line-height * $base-font-size * 2.25;
	letter-spacing: -1px;
	margin-bottom: 0;
	float: left;

	&,
	&:visited {
		color: $grey-color-dark;
	}
}


/* navigation */
.navbar {
	padding: 0;

	.nav-item {
		text-align: center;
	}
}


.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .active>.nav-link {
	font-size: 17px;
	padding: 13px 20px;
	position: relative;
}

.navbar-light .navbar-nav .nav-link {
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
}

.navbar-light .navbar-nav .nav-link:before {
	display: block;
	content: ' ';
	position: absolute;
	height: 3px;
	bottom: 0;
	background-color: #1f009f;

	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	-webkit-transition-timing-function: cubic-bezier(.58, .3, .005, 1);
	-o-transition-timing-function: cubic-bezier(.58, .3, .005, 1);
	transition-timing-function: cubic-bezier(.58, .3, .005, 1);

	width: 0;
	left: -10px;
	opacity: 0;
}

.navbar-light .navbar-nav .nav-link:visited,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-item.active .nav-link {
	color: #1f009f;
}

.navbar-light .navbar-nav .nav-link:hover:before,
.navbar-light .navbar-nav .nav-item.active .nav-link:before {
	width: 100%;
	left: 0;
	opacity: 1;
}


/**
   * Site footer
   */
.site-footer {
	border-top: 1px solid $grey-color-light;
	padding: $spacing-unit 0;
}

.footer-heading {
	@include relative-font-size(1.125);
	margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
	list-style: none;
	margin-left: 0;
}

.footer-col-wrapper {
	@include relative-font-size(0.9375);
	color: $grey-color;
	margin-left: calc(-1 * #{$spacing-unit} / 2);
	@extend %clearfix;
}

.footer-col {
	float: left;
	margin-bottom: calc($spacing-unit / 2);
	padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
	width: -webkit-calc(35% - (#{$spacing-unit} / 2));
	width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
	width: -webkit-calc(20% - (#{$spacing-unit} / 2));
	width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
	width: -webkit-calc(45% - (#{$spacing-unit} / 2));
	width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {

	.footer-col-1,
	.footer-col-2 {
		width: -webkit-calc(50% - (#{$spacing-unit} / 2));
		width: calc(50% - (#{$spacing-unit} / 2));
	}

	.footer-col-3 {
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width: calc(100% - (#{$spacing-unit} / 2));
	}
}

@include media-query($on-palm) {
	.footer-col {
		float: none;
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width: calc(100% - (#{$spacing-unit} / 2));
	}
}



/**
   * Page content
   */
.page-content {
	padding: $spacing-unit 0;
	flex: 1 0 auto;
}

.page-heading {
	@include relative-font-size(2);
}

.post-list-heading {
	@include relative-font-size(1.75);
}

.post-list {
	margin-left: 0;
	list-style: none;

	>li {
		margin-bottom: $spacing-unit;
	}
}

.post-meta {
	font-size: $small-font-size;
	color: $grey-color;
}

.post-link {
	display: block;
	@include relative-font-size(1.5);
}



/**
   * Posts
   */
.post-header {
	margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
	@include relative-font-size(2.625);
	letter-spacing: -1px;
	line-height: 1;

	@include media-query($on-laptop) {
		@include relative-font-size(2.25);
	}
}

.post-content {
	margin-bottom: $spacing-unit;

	h2 {
		@include relative-font-size(2);

		@include media-query($on-laptop) {
			@include relative-font-size(1.75);
		}
	}

	h3 {
		@include relative-font-size(1.625);

		@include media-query($on-laptop) {
			@include relative-font-size(1.375);
		}
	}

	h4 {
		@include relative-font-size(1.25);

		@include media-query($on-laptop) {
			@include relative-font-size(1.125);
		}
	}
}


// ======================

#skilfulness {
	clear: left;
	padding-top: 1em;
}

body {
	font-size: 16px;
	font-family: Helvetica, Arial, sans-serif;
}

b,
strong {
	color: #000;
}

ul {
	font-size: 15px;
}

/* Tablets (768px and below) */
@media only screen and (max-width: 768px) {

	/* Your CSS styles for tablets */
	body {
		background-color: #f9ffff;

		nav ul.navbar-nav {
			li {
				width: 100%;

				a {
					width: 100%;
				}
			}
		}
	}

}

/* Smartphones in Landscape (576px and below) */
@media only screen and (max-width: 576px) {

	/* Your CSS styles for smartphones in landscape */
	body {
		background-color: #fffff9;
	}

}


/* Smartphones in Portrait (480px and below) */
@media only screen and (max-width: 480px) {

	/* Your CSS styles for smartphones in portrait */
	body {
		background-color: #fff9ff;
	}

}